import React from "react";

//import Layout from "../components/layout";

import { Line, defaults } from "react-chartjs-2";

//import Img from "gatsby-image";

import { css } from "@emotion/core";

import HEAD from "../components/head";
//import logo from "../images/redapple_Final_Lightgray.png";

const u_rates = [25, -18.5, 15, -5, 25, -7, 16];
const s_rates = [25, -18.5, 5, 1, 0, 0, 0];

let date = new Date().getFullYear();

if (date % 2 !== 0) {
  date++;
}

const dates = [date - 8, date - 6, date - 4, date - 2, date, date + 2];

//const legend = {};

const fontFamily = '"Century Gothic", CenturyGothic, "Questrial", sans-serif';

const color_apple = "rgb(150, 42, 34)";

defaults.global.defaultFontFamily = fontFamily;

let x = 0 == false;

//TODO:
//chart dots - larger and solid

const chartData = {
  title: {},

  legend: {
    display: false,
    position: "top",
    fullWidth: true,
    reverse: false
    // labels: {
    //   fontColor: "red",
    //   fontFamily: fontFamily
    // }
  },
  //defaultFontFamily: fontFamily,
  options: {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `Rate change: ${tooltipItem.yLabel}%`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "% Rate Change"
          },
          ticks: {
            min: -30,
            max: 30,
            callback: function(value, index, values) {
              return value + "%";
            }
          }
        }
      ]
    }
  },

  data: {
    labels: dates, //["2010", "2012", "2014", "2016", "2018", "2020", "2022"],

    datasets: [
      {
        // label:"Rate change"

        fill: false,

        lineTension: 0.1,

        //backgroundColor: "orange", //dot, legend color

        //borderColor: "rgba(75,192,192,1)",
        borderColor: color_apple, //line color

        //borderCapStyle: "butt",

        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",

        // pointBorderColor: "rgba(75,192,192,1)",
        // pointBackgroundColor: "#fff",
        // pointBorderWidth: 1,
        // pointHoverRadius: 5,
        // pointHoverBackgroundColor: "rgba(75,192,192,1)",
        // pointHoverBorderColor: "rgba(220,220,220,1)",
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,
        // pointStyle: "star",

        data: u_rates
      }
    ]
  }
};

class RateStability extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      s: false,
      conditionsCounter: 0,
      conditions: [
        "Before aggregation, rates are uncertain",
        "Aggregate to spread risk over larger base",
        "Larger base creates stability",
        "Stabilization lowers rates"
      ],

      chartData
    };
  }

  addContition() {}

  componentDidMount() {
    const ccount = this.state.conditions.length;

    this.int = setInterval(() => {
      this.setState(state => {
        state.conditionsCounter++;
        //console.debug(state.conditionsCounter % ccount);

        if (state.conditionsCounter % ccount === ccount - 1) {
          state.s = !state.s;

          state.chartData.data.datasets[0].data = Object.assign(
            [],
            state.s ? s_rates : u_rates
          );
        } else if (state.conditionsCounter % ccount === 0) {
          state.s = !state.s;

          state.chartData.data.datasets[0].data = Object.assign(
            [],
            state.s ? s_rates : u_rates
          );
        }

        return state;
      });
    }, 3000);
  }

  updateData = () => {
    this.setState(state => {
      state.s = !state.s;

      state.chartData.data.datasets[0].data = Object.assign(
        [],
        state.s ? s_rates : u_rates
      );
      return state;
    });
  };

  render() {
    return (
      <div>
        <HEAD
          title="Explore solutions"
          keywords={[
            `red apple solutions explore rate stability`,
            `education`,
            `red`,
            `apple`
          ]}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <p>
                Aggregation of risk components through collaboration with many
                members ensures rate stability and greater purchasing strength
                to help keep rates low and stable.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col" />
            <div
              className="col ml-auto mr-auto"
              css={css`
                white-space: nowrap;
              `}
            >
              <ul className="list-inline">
                {Object.keys(this.state.conditions).map(k => {
                  const done =
                    this.state.conditionsCounter %
                      this.state.conditions.length >=
                    k;
                  const color = done ? "inherit" : "#d1d1d1";

                  return (
                    <li key={k} style={{ color }}>
                      {this.state.conditions[k]}{" "}
                      {
                        <span
                          style={{ color: done ? color_apple : "transparent" }}
                        >
                          &#9660;
                        </span>
                      }
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="col" />
          </div>
          <div className="row" style={{ display: "none" }}>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={e => this.updateData()}
              >
                {!this.state.s ? "STABILIZE" : "Show Fluxuation"}
              </button>

              <br />

              <br />

              <br />
            </div>
          </div>
          <div className="row ml-auto mr-auto">
            <Line {...this.state.chartData} />
          </div>
        </div>
      </div>
    );
  }
}

export default RateStability;
